import React from 'react';
import gsap from 'gsap';
import Link from 'gatsby-plugin-transition-link';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faVideo, faVolumeUp, faBook, faNewspaper } from '@fortawesome/free-solid-svg-icons';

const StoryCard = ({ storyData }) => {
  const imageIcon = (storyData.media && storyData.media.some((m) =>
    m.media_type !== null && m.media_type.indexOf('Photo') !== -1 ||
    m.media_type !== null && m.media_type.indexOf('Painting') !== -1 ||
    m.media_type !== null && m.media_type.indexOf('Printed Material') !== -1
  )) && (
      <span><FontAwesomeIcon icon={faImage} /></span>
  );

  const videoIcon = (storyData.media && storyData.media.some((m) => m.media_type !== null && m.media_type.indexOf('Video') !== -1)) && (
    <span><FontAwesomeIcon icon={faVideo} /></span>
  );

  const audioIcon = (storyData.media && storyData.media.some((m) => m.media_type !== null && m.media_type.indexOf('Audio') !== -1)) && (
    <span><FontAwesomeIcon icon={faVolumeUp} /></span>
  );

  const journalIcon = (storyData.media && storyData.media.some((m) => m.media_type !== null && m.media_type.indexOf('Journal') !== -1)) && (
    <span><FontAwesomeIcon icon={faBook} /></span>
  );

  const newspaperIcon = (storyData.media && storyData.media.some((m) => m.media_type !== null && m.media_type.indexOf('Newspaper article') !== -1)) && (
    <span><FontAwesomeIcon icon={faNewspaper} /></span>
  );


  return (
    <div id={storyData.story_id} className="col-xs-12 col-sm-6 col-md-6 col-lg-4 reveal-animate">
      <Link
        to={`/${_.kebabCase(storyData.decade[0])}/${_.kebabCase(_.split(storyData.name, '.').join(''))}`}
        tabIndex="0"
        title={`Read Story: ${storyData.name}`}
        state={{ fromSearch: false }}
      >
        <div className={`story-card ${storyData.color_scheme}`}>
          {storyData.featured_image !== null && (
            <div
              className="story-featured-image"
              style={{
                backgroundImage: `url(${_.replace(storyData.featured_image[0].image.featured, 'https://api.bcah.ca', 'https://bc-timeline-backend.azureedge.net')})`,
              }}
            ></div>
          )}
          <div className="story-decade-content mb-3 mb-md-5">
            <div className="story-decade-year text-right font-italic mb-3 mb-md-5">
              <span>{storyData.display_date}</span>
            </div>
            <div className="story-decade-title">
              <h1>{storyData.name}</h1>


              {storyData.deck && <p>{storyData.deck}</p>}
              {storyData.media &&
                <div className="story-media-type">
                  {imageIcon}
                  {videoIcon}
                  {audioIcon}
                  {journalIcon}
                  {newspaperIcon}
                </div>
              }
            </div>
          </div>
          <div className="story-decade-btn text-right">
            <span className="explore-arrow">
              <span className="label">Read Story</span>
              <span className="long-arrow" />
            </span>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default StoryCard;
