import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faPlay } from '@fortawesome/free-solid-svg-icons';

const WatchSeries = ({ link }) => {

  return (

    <a href={link} target="_blank" className="watch-series-button">
      <span className="icon"><FontAwesomeIcon icon={faPlay} /></span>
      <span className="label text-left">Watch Series at <span>KNOWLEDGE.CA</span></span>
      <span className="external-icon"><FontAwesomeIcon icon={faExternalLinkAlt} /></span>
    </a>

  );
}

export default WatchSeries;