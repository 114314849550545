import React, { useEffect, useRef, useState } from 'react';
import { graphql, Link } from 'gatsby';
import gsap from 'gsap';
import { Container, Row, Col } from 'react-bootstrap';
import _ from 'lodash';
import Layout from '../components/layout';
import SEO from '../components/seo';
import StoryCard from '../components/story';
import WatchSeries from '../components/watchSeries';
import SocialShare from '../components/socialShare';
import Footer from '../components/footer';


export default function DecadeStories({ data, location, pageContext }) {
  const dataOptions = {
    url: location.href,
    title: `${data.decades.value} - ${data.site.siteMetadata.title}`,
    image: data.file && location.origin + data.file.publicURL
  };

  const bgColor = {
    "dark-blue-scheme": "#1f323c",
    "orange-scheme": "#a55502",
    "blue-scheme": "#89a6aa"
  };

  return (
    <Layout >
      <SEO
        title={dataOptions.title}
        url={dataOptions.url}
        image={dataOptions.image}
        theme_color={bgColor[pageContext.colorScheme]}
      />
      <div className="decade-stories-wrapper main-fixed-wrapper">
        <div className="decade-header-wrapper reveal-animate">
          <Container fluid="lg">
            <Row className="align-items-center">
              <Col xs={12} sm={6}>
                <a
                  href={`/#${_.toLower(data.decades.value)}`}
                  className="back-link mb-5 mb-sm-0"
                >
                  <span className="explore-arrow position-relative">
                    <span className="long-arrow left-arrow" />
                    <span className="label">Back to Decades</span>
                  </span>
                </a>
              </Col>
              <Col xs={12} sm={6} className="text-right">
                <h1 className="decade-heading script-heading"
                  dangerouslySetInnerHTML={{ __html: data.decades.value.indexOf('Pre-') !== -1 ? _.replace(data.decades.value, 'Pre-', '<span>Pre-</span>') : data.decades.value }}
                ></h1>
              </Col>
            </Row>
          </Container>
        </div>
        <Container fluid>
          <Row noGutters>
            {data.allStories.nodes.length > 0 ? (
              data.allStories.nodes.map((story, index) =>
                <StoryCard key={index} storyData={story} />
              )
            ) : (
              <div className="col-sm-12">
                <p className="text-center">Sorry, there are no stories in this decade.</p>
              </div>
            )}
          </Row>
        </Container>
      </div>
      <div className="reveal-animate">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} sm={6} className="text-center">
              <a
                href={`/#${_.toLower(data.decades.value)}`}
                className="back-link mb-5 mb-sm-0"
              >
                <span className="explore-arrow position-relative">
                  <span className="long-arrow left-arrow" />
                  <span className="label">Back to Decade</span>
                </span>
              </a>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="mt-sm-5 pt-sm-5 text-center reveal-animate">
        <Container>
          <Row>
            <Col><WatchSeries link={data.site.siteMetadata.watch_series_link} /></Col>
          </Row>
        </Container>
      </div>

      <SocialShare dataOptions={dataOptions} />
      <Footer>
        <div id="footer-color"></div>
      </Footer>

    </Layout>
  );
}

export const query = graphql`
	query($slug: String, $id: String) {
		site {
			siteMetadata {
				title
        watch_series_link
			}
		}

		decades(value: { eq: $slug }) {
			value
		}

    file(name: { eq: $id }) {
      publicURL
      name
    }

		allStories(filter: { decade: { eq: $slug } }, sort: { fields: start_date, order: ASC }) {
			nodes {
				story_id
				name
        deck
				display_date
				decade
				color_scheme
				featured_image {
					image {
						featured
					}
				}
				media {
					media_type
				}
			}
		}
	}
`;
